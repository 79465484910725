import { forwardRef } from "react";

import Link from "./link";
import { Component } from "./styles";
import { BaseButtonProps } from "./types";

export default forwardRef<
  HTMLButtonElement,
  BaseButtonProps
>(function BaseButton(props, ref) {
  if (
    typeof props.to !== "undefined" &&
    typeof props.LinkComponent === "undefined"
  ) {
    props = { ...props, LinkComponent: Link };
  }

  return (
    <Component ref={ref} {...props} />
  );
});
