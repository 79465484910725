import { createContext, useCallback, useEffect, useState } from "react";

import { ImageData } from "components/common/image/types";
import { STORAGE_KEYS } from "settings";

import Footer from "./footer";
import FrontChat from "./front-chat";
import Header from "./header";
import Intercom from "./intercom";
import { Body, Container } from "./styles";
import { MainLayoutProps } from "./types";

const DEFAULT_HOME_PATH = "/";

export const MainLayoutContext = createContext<{
  homePath: {
    set: (value: string) => void;
    value: string;
  };
  partnerLogo: {
    set: (value: ImageData | null) => void;
    value: ImageData | null;
  };
  showOnboardingLink: {
    set: (value: boolean) => void;
    value?: boolean;
  };
}>({
  homePath: {
    set: () => {},
    value: DEFAULT_HOME_PATH,
  },
  partnerLogo: {
    set: () => {},
    value: null,
  },
  showOnboardingLink: {
    set: () => {},
  },
});

export default function MainLayout({ children, location }: MainLayoutProps) {
  const [partnerLogo, setPartnerLogo] = useState<ImageData | null>(null);
  const [showOnboardingLink, setShowOnboardingLink] = useState<boolean>();

  const [homePath, setHomePath] = useState((
    typeof window !== "undefined" &&
    window.sessionStorage.getItem(STORAGE_KEYS.homePath)
  ) || DEFAULT_HOME_PATH);

  const setHomePathContext = useCallback((value: string) => {
    if (typeof window !== "undefined") {
      window.sessionStorage.setItem(STORAGE_KEYS.homePath, value);
    }
    setHomePath(value);
  }, []);

  useEffect(() => {
    // If showOnboardingLink is undefined, then set it to true. This is needed
    // to avoid flashing the onboarding link on mobile screens when it's set to
    // false. Also, if we set this value to false by default, then we would need
    // to call setShowOnboardingLink(true) on every page that shows it.
    setShowOnboardingLink(showOnboardingLink => (
      typeof showOnboardingLink === "undefined" || showOnboardingLink
    ));
  }, []);

  return (
    <MainLayoutContext.Provider value={{
      homePath: {
        set: setHomePathContext,
        value: homePath,
      },
      partnerLogo: {
        set: setPartnerLogo,
        value: partnerLogo,
      },
      showOnboardingLink: {
        set: setShowOnboardingLink,
        value: showOnboardingLink,
      },
    }}>
      <Container>
        <Header location={location} />

        <Body>
          {children}
        </Body>

        <Footer />
      </Container>

      {
        !location.pathname.startsWith("/app/") &&
        process.env.GATSBY_CHAT_SERVICE === "front" && (
          <FrontChat />
        )
      }

      {
        !location.pathname.startsWith("/app/") &&
        process.env.GATSBY_CHAT_SERVICE === "intercom" && (
          <Intercom />
        )
      }
    </MainLayoutContext.Provider>
  );
};
