import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";

import SocialLogosComponent from "../top/social-logos";
import { SocialLogosProps } from "../top/social-logos/types";

export const CloseButton = styled(IconButton, {
  label: "close-button",
})`
  color: ${({ theme }) => theme.palette.p_black.text};
  position: absolute;
  top: ${({ theme }) => theme.spacing(1.75)};
  right: ${({ theme }) => theme.spacing(1.75)};

  & > svg {
    fill: currentColor;
    width: ${({ theme }) => theme.spacing(1.5)};
    height: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const Container = styled(Modal, {
  label: "newsletter-modal",
})`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Content = styled("div", {
  label: "content",
})`
  font-family: ${({ theme }) => theme.typography.main.fontFamily};
  text-align: center;

  & > h3 {
    font-size: 60px;
    font-style: italic;
    font-weight: 400;
    line-height: 1.167;

    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 48px;
    }
  }

  & > p {
    color: ${({ theme }) => theme.palette.p_black.text};
    line-height: 1.25;
    margin: ${({ theme }) => theme.spacing(3, "auto", 0)};
    max-width: ${({ theme }) => theme.spacing(50)};

    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 14px;
      max-width: ${({ theme }) => theme.spacing(42)};
    }
  }
`;

export const Dialog = styled("div", {
  label: "dialog",
})`
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: 0px 4px 40px rgba(170, 170, 170, 0.25);
  padding: ${({ theme }) => theme.spacing(6, 10)};
  position: relative;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: ${({ theme }) => theme.spacing(7, 2)};
    width: 80vw;
  }
`;

export const NewsletterContainer = styled("div", {
  label: "newsletter-container",
})`
  margin-top: ${({ theme }) => theme.spacing(3)};

  .button {
    font-size: 14px;

    ${({ theme }) => theme.breakpoints.down("md")} {
      margin: 0;
    }
  }

  .form {
    ${({ theme }) => theme.breakpoints.down("md")} {
      display: grid;
      gap: ${({ theme }) => theme.spacing(1)};
      grid-auto-rows: 1fr;
    }
  }
`;

export const SocialLogos = (props: Partial<SocialLogosProps>) => (
  <SocialLogosComponent variant="standard" {...props} />
)

export const SocialLogosContainer = styled("div", {
  label: "social-logos-container",
})`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette.p_black.text};
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-top: ${({ theme }) => theme.spacing(7)};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
