import { styled } from "@mui/material/styles";

import MailIconAsset from "assets/images/icons/mail.svg";
import PhoneIconAsset from "assets/images/icons/phone.svg";
import ButtonComponent from "components/common/button";
import InputComponent from "components/common/input";

import { FULL_SCREEN_MEDIA_QUERY } from "../../styles";

export const Button = styled(ButtonComponent, {
  label: "button",
})`
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
  padding-right: ${({ theme }) => theme.spacing(9)};
  padding-left: ${({ theme }) => theme.spacing(9)};

  ${FULL_SCREEN_MEDIA_QUERY} {
    flex: 1;
  }
`;

export const Container = styled("form", {
  label: "form",
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  ${FULL_SCREEN_MEDIA_QUERY} {
    flex: 1;
  }

  & > h3 {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 16px;
    font-weight: 700;
    line-height: 1.8;
    margin-top: auto;

    ${FULL_SCREEN_MEDIA_QUERY} {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      margin-top: 0;
    }
  }
`;

export const Input = styled(InputComponent, {
  label: "input",
})`
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
`;

export const MailIcon = styled(MailIconAsset, {
  label: "mail-icon",
})`
  fill: ${({ theme }) => theme.palette.primary.main};
  flex: 0 0 ${({ theme }) => theme.spacing(1.75)};
  width: ${({ theme }) => theme.spacing(1.75)};
  height: auto;
`;

export const PhoneIcon = styled(PhoneIconAsset, {
  label: "phone-icon",
})`
  fill: ${({ theme }) => theme.palette.primary.main};
  flex: 0 0 ${({ theme }) => theme.spacing(1.75)};
  width: ${({ theme }) => theme.spacing(1.75)};
  height: auto;
`;

export const Row = styled("div", {
  label: "row",
})`
  display: flex;
  margin: ${({ theme }) => theme.spacing(2.5, -0.5, 0)};

  &:nth-last-of-type(2) {
    ${FULL_SCREEN_MEDIA_QUERY} {
      margin-bottom: auto;
    }
  }
`;
