import { styled } from "@mui/material/styles";

export const Container = styled("footer", {
  label: "footer",
})`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Content = styled("div", {
  label: "content",
})`
  margin: 0 auto;
  max-width: 1440px;
  padding: ${({ theme }) => theme.spacing(8, 10)};

  ${({ theme }) => theme.breakpoints.down("tablet")} {
    padding: ${({ theme }) => theme.spacing(8, 4)};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: ${({ theme }) => theme.spacing(4, 2, 2)};
  }
`;
