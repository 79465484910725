import { RTNode, RTTextNode } from "@prismicio/types";
import { RichTextField } from "components/common/rich-text/types";

const REGEXP = /{{ *([A-Za-z0-9]+?) *}}/g;

export default function interpolation(
  field: RichTextField<"richText">,
  values: Record<string, string> = {},
): RichTextField<"richText"> {
  if (!field.richText || !field.richText.length) {
    return field;
  }

  const richText: RTNode[] = [];

  for (const node of (field.richText as RTTextNode[])) {
    richText.push({
      ...node,
      text: node.text.replace(REGEXP, (template, property) => {
        return typeof values[property] === "string"
          ? values[property]
          : template;
      }),
    });
  }

  if (!richText.length) {
    return field;
  }

  return {
    ...field,
    richText: richText as [RTNode, ...RTNode[]],
  };
}
