import { forwardRef } from "react";

import LinkComponent from "components/common/link";

import { LinkProps } from "./types";

export default forwardRef<HTMLAnchorElement, LinkProps>(function Link({
  ...props
}, ref) {
  return (
    <LinkComponent
      {...props}
      ref={ref}
    />
  );
});
