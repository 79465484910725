import { FilledLinkToDocumentField } from "@prismicio/types";

const TYPE_MAP: { [key: string]: string } = {
  "404_page": "/404/",
  blog_page: "/blog/",
  careers_page: "/careers/",
  contact_legal: "/additional-disclosures/",
  contact_us: "/contact/",
  cover_page: "/cover/",
  faq_page: "/faq/",
  privacy_policy: "/privacy-policy/",
  terms_of_use: "/terms-of-use/",
};

export default function linkResolver(document: FilledLinkToDocumentField) {
  if (TYPE_MAP[document.type]) {
    return TYPE_MAP[document.type];
  } else if (document.type !== "blog") {
    return "/";
  }

  const { data } = document as {
    data?: {
      types: [{ link: { slug: string } }],
    },
  };

  // Use "post" if no type is associated to the post
  const type = data?.types[0].link.slug || "post";

  return `/blog/${type}/${document.uid}/`;
};
