// Returns an error string or false if invalid, otherwise true if valid
export type ValidationFunction = (value: unknown) => string | boolean | null;

export interface ValidationResult {
  helperText: string | null;
  valid: boolean | null;
}

export const DEFAULT_VALIDATION_RESULT: ValidationResult = {
  helperText: null,
  valid: null,
};

export default function validation(
  validate: ValidationFunction | ValidationFunction[],
  value: unknown
): ValidationResult {
  if (!Array.isArray(validate)) {
    const valid = validate(value);

    return valid === null || typeof valid === "boolean"
      ? { helperText: null, valid }
      : { helperText: valid, valid: false };
  }

  let result: ValidationResult = DEFAULT_VALIDATION_RESULT;

  for (const validateFunction of validate) {
    result = validation(validateFunction, value);

    if (result.valid === false) {
      return result;
    }
  }

  return result;
}
