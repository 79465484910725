import { RichTextField } from "components/common/rich-text/types";

type Paragraph = {
  spans: Span[];
  text: string;
  type: Type;
};

type Span = {
  start: number;
  end: number;
  type: string;
};

type Tag =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "li"
  | "p";

type Type =
  | "heading1"
  | "heading2"
  | "heading3"
  | "heading4"
  | "heading5"
  | "heading6"
  | "list-item"
  | "paragraph";

const tagDict: { [key in Tag]: Type } = {
  h1: "heading1",
  h2: "heading2",
  h3: "heading3",
  h4: "heading4",
  h5: "heading5",
  h6: "heading6",
  li: "list-item",
  p: "paragraph",
};

const typeDict: { [key in Type]: Tag } = {
  heading1: "h1",
  heading2: "h2",
  heading3: "h3",
  heading4: "h4",
  heading5: "h5",
  heading6: "h6",
  "list-item": "li",
  paragraph: "p",
};

export type MigrationType = {
  [key in (Tag | "*" | "h*")]?: Tag;
} | "string" | Tag;

function merge(destination: Paragraph, paragraph: Paragraph) {
  if (paragraph.spans.length) {
    const textLength = destination.text.length + 1;
    // + 1 for the space added below (i.e. ` ${paragraph.text}`)

    for (const { start, end, type } of paragraph.spans) {
      destination.spans.push({
        start: start + textLength,
        end: end + textLength,
        type
      });
    }
  }

  destination.text += ` ${paragraph.text}`;
}

function migrate(
  value: unknown,
  type: MigrationType,
) {
  if (Array.isArray(value)) {
    if (type === "string") {
      let text = value[0].text;

      for (let index = 1; index < value.length; index++) {
        const paragraph = value[index] as Paragraph;

        if ("text" in paragraph) {
          text += ` ${paragraph.text}`;
        }
      }

      return text;
    } else if (typeof type === "string") {
      const richText = [{
        ...value[0],
        type: tagDict[type as Tag],
      }] as Paragraph[];

      for (let index = 1; index < value.length; index++) {
        merge(richText[0], value[index]);
      }

      return { richText };
    } else {
      const richText = [] as Paragraph[];

      for (let index = 0; index < value.length; index++) {
        const lastParagraph = richText[richText.length - 1];
        const paragraph = value[index] as Paragraph;
        const tag = typeDict[paragraph.type];

        const tagType = tag && type["h*"] && tag[0] === "h"
          ? type["h*"]
          : (type[tag] || type["*"]);

        const paragraphType = tagType
          ? tagDict[tagType]
          : paragraph.type;

        if (
          lastParagraph &&
          lastParagraph.type.startsWith("heading") &&
          lastParagraph.type === paragraphType
        ) {
          merge(lastParagraph, paragraph);
        } else {
          richText.push({
            ...paragraph,
            type: paragraphType,
          });
        }
      }

      return { richText };
    }
  } else if (
    typeof value === "string" &&
    typeof type === "string" &&
    type !== "string"
  ) {
    const richText = [{
      spans: [],
      text: value,
      type: tagDict[type as Tag]
    }];

    return { richText };
  }

  return value;
}

export default function migration(
  value: RichTextField<"richText" | "text"> | unknown,
  type: MigrationType,
) {
  if (value != null && typeof value === "object") {
    if (type === "string") {
      if ("text" in value) {
        return (value as RichTextField<"text">).text;
      } else if ("richText" in value) {
        return migrate(
          (value as RichTextField<"richText">).richText,
          "string"
        );
      }
    } else if ("richText" in value) {
      return {
        ...(value as RichTextField<"richText">),
        ...migrate((value as RichTextField<"richText">).richText, type),
      };
    }
  }

  return migrate(value, type);
}
