import { useContext } from "react";

import useScrolled from "hooks/use-scrolled";

import { MainLayoutContext } from "..";
import Drawer from "./drawer";
import Logo from "./logo";
import { Container, List, ListItem, Nav, OnboardingLink } from "./styles";
import { HeaderProps } from "./types";

export default function Header({ location }: HeaderProps) {
  const scrolled = useScrolled(60);

  const { showOnboardingLink } = useContext(MainLayoutContext);

  const collapsed = scrolled || location.pathname.startsWith('/blog');
  const className = collapsed ? "collapsed" : "";

  return (
    <Container className={className} id="faye-layout-header">
      <Nav className={className}>
        <Logo />

        <List>
          <ListItem>
            <Drawer/>
          </ListItem>

          {showOnboardingLink.value && (
            <ListItem>
              <OnboardingLink
                className={className}
                placement="upper_bar_cta"
              />
            </ListItem>
          )}
        </List>
      </Nav>
    </Container>
  );
};
