import { styled, Theme } from "@mui/material/styles";

export const Body = styled("main", {
  label: "body",
})`
  background: ${({ theme }: { theme: Theme }) => (
    `var(--faye-page-background, ${theme.palette.primary.light})`
  )};
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Container = styled("div", {
  label: "main-layout",
})`
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  padding: 0;
`;
