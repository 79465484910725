import { Link as GatsbyLinkComponent } from "gatsby";
import { styled } from "@mui/material/styles";

export const GatsbyLink = styled(GatsbyLinkComponent, {
  label: "gatsby-link"
})`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
`;

export const Link = styled("a", {
  label: "link"
})`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: none;
`;
