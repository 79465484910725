import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "social-logos",
})`
  display: flex;

  &.compact {
    margin: ${({ theme }) => theme.spacing(0, -1, 0, 0)};
  }

  &.standard {
    margin: ${({ theme }) => theme.spacing(0, -1.5, 0, 0)};
  }
`;

export const Link = styled("a", {
  label: "link",
})`
  color: inherit;

  &.compact {
    margin: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  }

  &.standard {
    margin: ${({ theme }) => theme.spacing(0, 1.5, 0, 0)};
  }

  & > svg {
    fill: currentColor;
  }
`;
