import { graphql, useStaticQuery } from "gatsby";

import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import AdvisorsDialog from "components/common/advisors-dialog";
import richTextMigration from "utils/rich-text/migration";

import Bottom from "./bottom";
import NewsletterModal from "./newsletter-modal";
import { Container, Content } from "./styles";
import Top from "./top";
import { DataProps } from "./types";

export const FooterContext = createContext({
  dialogs: {
    showAdvisors: {
      set: (() => {}) as Dispatch<SetStateAction<boolean | null>>,
      value: null as boolean | null,
    },
    showNewsletter: {
      set: (() => {}) as Dispatch<SetStateAction<boolean | null>>,
      value: null as boolean | null,
    },
  }
});

export default function Footer() {
  const [showAdvisorsDialog, setShowAdvisorsDialog] = useState<
    boolean | null
  >(null);

  const [showNewsletterDialog, setShowNewsletterDialog] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("advisors")) {
      setShowAdvisorsDialog(true);
    }

    if (searchParams.has("newsletter")) {
      setShowNewsletterDialog(true);
    }
  }, []);

  const {
    prismicFooter: {
      data,
    },
    site,
  }: DataProps = useStaticQuery(graphql`
    query {
      prismicFooter {
        data {
          body {
            ... on PrismicFooterDataBodyLinkGroup {
              primary {
                title: group_name {
                  text
                }
              }

              items {
                label: link_name {
                  text
                }
                path: link_path {
                  text
                }
              }
            }
          }

          disclaimer {
            richText
          }
        }
      }

      site {
        year: buildTime(formatString: "YYYY")
      }
    }
  `);

  return (
    <FooterContext.Provider value={{
      dialogs: {
        showAdvisors: {
          set: setShowAdvisorsDialog,
          value: showAdvisorsDialog,
        },
        showNewsletter: {
          set: setShowNewsletterDialog,
          value: showNewsletterDialog,
        },
      }
    }}>
      <Container data-nosnippet>
        <Content>
          <Top
            lists={data.body.map(list => ({
              title: richTextMigration(list.primary.title, "string"),
              items: list.items.map(item => ({
                label: richTextMigration(item.label, "string"),
                path: richTextMigration(item.path, "string"),
              })),
            }))}
          />

          <Bottom disclaimer={data.disclaimer} year={site.year} />
        </Content>
      </Container>

      {showAdvisorsDialog !== null && (
        <AdvisorsDialog
          onClose={() => {
            setShowAdvisorsDialog(false);
          }}
          open={showAdvisorsDialog}
        />
      )}

      {showNewsletterDialog !== null && (
        <NewsletterModal
          onClose={() => {
            setShowNewsletterDialog(false);
          }}
          open={showNewsletterDialog}
        />
      )}
    </FooterContext.Provider>
  );
};
