import { styled } from "@mui/material/styles";

import Button from "components/common/button";

export const Container = styled(Button, {
  label: "onboarding-link",
})`
  font-size: 14px;
  line-height: 2;
  padding: ${({ theme }) => theme.spacing(0.75, 9)};
  text-align: center;
  text-transform: none;
`;
