import classNames from "classnames";
import { forwardRef } from "react";

import { Component } from "./styles";
import { ButtonProps } from "./types";

export default forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function Button({
  className,
  variant = "standard",
  ...props
}, ref) {
  return (
    <Component
      className={classNames(className, variant !== "standard" && variant)}
      ref={ref}
      {...props}
    />
  );
});
