import FacebookLogo from "assets/images/icons/facebook.svg";
import TwitterLogo from "assets/images/icons/twitter.svg";
import LinkedInLogo from "assets/images/icons/linkedin.svg";
import InstagramLogo from "assets/images/icons/instagram.svg";

import { Container, Link } from "./styles";
import { SocialLogosProps } from "./types";

const FACEBOOK_LINK = "https://www.facebook.com/FayeTravelInsurance";
const INSTAGRAM_LINK = "https://www.instagram.com/faye.travel/";
const LINKEDIN_LINK = "https://www.linkedin.com/company/fayetravelinsurance";
const TWITTER_LINK = "https://twitter.com/FayeTravel";

export default function SocialLogos({ variant }: SocialLogosProps) {
  return (
    <Container className={variant}>
      <Link
        className={variant}
        href={FACEBOOK_LINK}
        rel="noreferrer"
        target="_blank"
      >
        <FacebookLogo />
      </Link>

      <Link
        className={variant}
        href={TWITTER_LINK}
        rel="noreferrer"
        target="_blank"
      >
        <TwitterLogo />
      </Link>

      <Link
        className={variant}
        href={LINKEDIN_LINK}
        rel="noreferrer"
        target="_blank"
      >
        <LinkedInLogo />
      </Link>

      <Link
        className={variant}
        href={INSTAGRAM_LINK}
        rel="noreferrer"
        target="_blank"
      >
        <InstagramLogo />
      </Link>
    </Container>
  );
};
