export function cleanPhoneNumber(value: string) {
  let prefix = "";
  let result = value.trimStart();

  if (result[0] === "+") {
    prefix = "+";
    result = result.slice(1);
  }

  return prefix + result
    .replace(/[^\d() \-.]+/g, "")
    .replace(/(\(|\)| |-|\.)(?=\1+)/g, "");
}
