import { useContext, useState } from "react";

import Link from "components/common/link";

import { MainLayoutContext } from "../..";

import {
  Container,
  Image,
  LogoContainer,
  PartnerLogo,
  PlusIcon,
  Version,
} from "./styles";

export default function Logo() {
  const { homePath, partnerLogo } = useContext(MainLayoutContext);
  const [showVersion, setShowVersion] = useState(false);

  return (
    <Container>
      <LogoContainer>
        <Link to={homePath.value}>
          <Image />
        </Link>

        <Version
          className={showVersion ? "show" : ""}
          onClick={event => {
            if (event.detail === 3) {
              setShowVersion(showVersion => !showVersion);
            }
          }}
        >
          {process.env.NODE_ENV === "test"
            ? "0.0.0" // Hide version number on tests
            : process.env.GATSBY_FAYE_VERSION
          }
        </Version>
      </LogoContainer>

      {partnerLogo.value?.url && (
        <>
          <PlusIcon />
          <PartnerLogo {...partnerLogo.value} />
        </>
      )}
    </Container>
  );
};
