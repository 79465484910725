import AppStore from "assets/images/app-store.svg";
import GooglePlay from "assets/images/google-play.svg";

import { Container, Link, List, Title } from "./styles";

const APP_STORE_URL = "https://assets.withfaye.com/ios";
const GOOGLE_PLAY_URL = "https://assets.withfaye.com/android";
const TITLE = "Download the app";

export default function AppLinks() {
  return (
    <Container>
      <Title>
        {TITLE}
      </Title>

      <List>
        <Link href={APP_STORE_URL} rel="noopener" target="_blank">
          <AppStore />
        </Link>
        <Link href={GOOGLE_PLAY_URL} rel="noopener" target="_blank">
          <GooglePlay />
        </Link>
      </List>
    </Container>
  );
};
