import { Container } from "./styles";
import { AppLayoutProps } from "./types";

export default function AppLayout({ children }: AppLayoutProps) {
  return (
    <Container>
      {children}
    </Container>
  );
};
