import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "columns",
})`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1)};
  grid-template-columns: 1fr 1fr 1fr;
  min-width: ${({ theme }) => theme.spacing(45)};
  width: 50%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 45%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr 1fr;
    gap: ${({ theme }) => theme.spacing(5)};
    min-width: auto;
    width: 100%;
  }
`;
