import { styled } from "@mui/material/styles";

import CheckCircleIconAsset from "assets/images/icons/check-circle.svg";
import ButtonComponent from "components/common/button";

import { FULL_SCREEN_MEDIA_QUERY } from "../../styles";

export const Button = styled(ButtonComponent, {
  label: "button",
})`
  margin-top: auto;
  padding-right: ${({ theme }) => theme.spacing(9)};
  padding-left: ${({ theme }) => theme.spacing(9)};

  ${FULL_SCREEN_MEDIA_QUERY} {
    width: 100%;
  }
`;

export const CheckCircleIcon = styled(CheckCircleIconAsset, {
  label: "check-circle-icon",
})`
  fill: ${({ theme }) => theme.palette.primary.main};
  margin-top: auto;
  width: ${({ theme }) => theme.spacing(8.875)};
  height: auto;

  ${FULL_SCREEN_MEDIA_QUERY} {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
    margin-top: 0;
  }
`;

export const Container = styled("div", {
  label: "confirmation",
})`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing(4)};

  ${FULL_SCREEN_MEDIA_QUERY} {
    align-items: center;
    flex: 1;
    padding-top: 0;
    text-align: center;
    z-index: 1;
  }

  & > h3 {
    font-family: "Utopia Std";
    font-size: 36px;
    font-weight: 400;
    line-height: 1.28;
    margin-top: ${({ theme }) => theme.spacing(6)};

    ${FULL_SCREEN_MEDIA_QUERY} {
      margin-top: ${({ theme }) => theme.spacing(4)};
    }
  }

  & > p {
    font-weight: 700;
    line-height: 1.8;
    margin: ${({ theme }) => theme.spacing(4, 0)};

    ${FULL_SCREEN_MEDIA_QUERY} {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
