import { styled } from "@mui/material/styles";

export const Container = styled("div", {
  label: "app-links",
})`
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  padding: ${({ theme }) => theme.spacing(0, 4)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 13px;
    padding: ${({ theme }) => theme.spacing(4, 0)};
  }
`;

export const Link = styled("a", {
  label: "link",
})`
  margin: ${({ theme }) => theme.spacing(0, 1.5, 1.5, 0)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: ${({ theme }) => theme.spacing(0, 1, 1, 0)};
  }

  & > svg {
    display: block;
    width: 138px;
    height: auto;
  }
`;

export const List = styled("div", {
  label: "list",
})`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing(0, -1.5, -1.5, 0)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin: ${({ theme }) => theme.spacing(0, -1, -1, 0)};
  }
`;

export const Title = styled("div", {
  label: "title",
})`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
