import { ImageProps } from "./types";

export default function Image({
  alt,
  dimensions,
  url,
  ...props
}: ImageProps) {
  if (!dimensions) {
    return (
      <img
        {...props}
        alt={alt || ""}
        src={url}
      />
    );
  }

  return (
    <img
      {...props}
      alt={alt || ""}
      src={url}
      width={dimensions.width}
      height={dimensions.height}
    />
  );
};
