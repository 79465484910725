import { PrismicRichText } from "@prismicio/react";

import richTextInterpolation from "utils/rich-text/interpolation";
import richTextMigration from "utils/rich-text/migration";

import { RichTextField, RichTextProps } from "./types";

export default function RichText({
  field,
  interpolation,
  migration,
  ...props
}: RichTextProps<"richText">) {
  let result: RichTextField<"richText"> = field;

  if (interpolation) {
    result = richTextInterpolation(result, interpolation);
  }

  if (migration) {
    result = richTextMigration(result, migration) as RichTextField<"richText">;
  }

  if (!result?.richText?.length) {
    return null;
  }

  return (
    <PrismicRichText
      field={result.richText}
      {...props}
    />
  );
};
