import CloseIcon from "assets/images/icons/close.svg";
import Newsletter from "components/common/newsletter";

import { NewsletterModalProps } from "./types";

import {
  CloseButton,
  Container,
  Content,
  Dialog,
  NewsletterContainer,
  SocialLogos,
  SocialLogosContainer,
} from "./styles";

const TITLE = "Unpack good content";
const LEAD =
  "Stay in the know when new product updates, announcements, travel inspo " +
  "and more land here.";

export function NewsletterModal({ onClose, open }: NewsletterModalProps) {
  return (
    <Container onClose={onClose} open={open}>
      <Dialog>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        <Content>
          <h3>{TITLE}</h3>
          <p>{LEAD}</p>
        </Content>

        <NewsletterContainer>
          <Newsletter
            buttonProps={{ className: "button" }}
            className="form"
            placement="footer"
          />
        </NewsletterContainer>

        <SocialLogosContainer>
          <SocialLogos />
        </SocialLogosContainer>
      </Dialog>
    </Container>
  );
};

export default NewsletterModal;
