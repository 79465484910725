import { Container } from "./styles";
import { InputProps } from "./types";

export default function Input({
  className,
  onChange,
  status,
  value,
}: InputProps) {
  return (
    <Container
      className={className}
      disabled={status !== null && status !== "error"}
      error={status === "error"}
      onChange={onChange}
      placeholder="Email address"
      type="email"
      value={value}
    />
  );
};
