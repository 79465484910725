import { useMediaQuery } from "@mui/material";

import RichText from "components/common/rich-text";

import { FULL_SCREEN_MEDIA_QUERY } from "../../styles";
import { Button, CheckCircleIcon, Container } from "./styles";
import { ConfirmationProps } from "./types";

export default function Confirmation({
  ctaButtonLabel,
  description,
  firstName,
  onClose,
  title,
}: ConfirmationProps) {
  const isFullScreen = useMediaQuery(FULL_SCREEN_MEDIA_QUERY);

  return (
    <Container>
      <CheckCircleIcon/>

      <RichText field={title} interpolation={{ firstName }}/>
      <RichText field={description}/>

      <Button
        onClick={onClose}
        to="/"
        variant={isFullScreen ? "outlined" : "standard"}
      >
        {ctaButtonLabel}
      </Button>
    </Container>
  );
}
