import { useState } from "react";

import Confirmation from "./confirmation";
import Form from "./form";
import { Container } from "./styles";
import { CtaProps } from "./types";

export default function Cta({ confirmation, form, onClose }: CtaProps) {
  const [firstName, setFirstName] = useState<string | null>(null);

  return (
    <Container>
      {!firstName && (
        <Form
          {...form}
          onSubmit={({ firstName }) => {
            setFirstName(firstName);
          }}
        />
      )}

      {firstName && (
        <Confirmation
          {...confirmation}
          firstName={firstName}
          onClose={onClose}
        />
      )}
    </Container>
  );
}
