import ClickAwayListener from "@mui/material/ClickAwayListener";
import classNames from "classnames";
import { useState } from "react";

import { Container, DrawerClose, DrawerOpen, Link, Trigger } from "./styles";

export default function Drawer() {
  const [drawerActive, setDrawerActive] = useState(false);

  const linkProps = {
    onClick: () => {
      setDrawerActive(false);
    },
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (drawerActive) {
          setDrawerActive(false);
        }
      }}
    >
      <div>
        <Trigger
          disableRipple
          onClick={() => setDrawerActive(drawerActive => !drawerActive)}
        >
          {drawerActive ? <DrawerClose/> : <DrawerOpen/>}
        </Trigger>

        <Container className={classNames({ active: drawerActive })}>
          <Link {...linkProps} to="/faq/">FAQ</Link>
          <Link {...linkProps} to="/cover/">What's covered</Link>
        </Container>
      </div>
    </ClickAwayListener>
  );
};
