import { filledInputClasses } from "@mui/material/FilledInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import TextField, { type FilledTextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

export const Component = styled(forwardRef<
  HTMLDivElement,
  Omit<FilledTextFieldProps, "variant">
>(function Input({
  InputProps = {},
  ...props
}, ref) {
  return (
    <TextField
      ref={ref}
      {...props}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
      }}
      size="small"
      variant="filled"
    />
  );
}), {
  label: "input",
})`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 14px;
  }

  .${inputLabelClasses.root} {
    font-size: inherit;
    margin-left: -13px;
    max-width: none;
    padding: ${({ theme }) => theme.spacing(0.25)};
    transition: ${({ theme }) => theme.transitions.create([
      "padding-top",
      "transform",
    ])};

    &,
    &.${inputLabelClasses.error},
    &.${inputLabelClasses.focused} {
      color: ${({ theme }) => theme.palette.textbox.label};
    }

    &.${inputLabelClasses.shrink} {
      padding-top: ${({ theme }) => theme.spacing(0.5)};
    }

    .${inputLabelClasses.asterisk} {
      color: inherit;
    }
  }

  .${filledInputClasses.root} {
    color: ${({ theme }) => theme.palette.textbox.text};
    font-size: inherit;
    padding-top: ${({ theme }) => theme.spacing(0.25)};
    padding-bottom: ${({ theme }) => theme.spacing(0.25)};

    &,
    &:hover,
    &.${filledInputClasses.focused} {
      background: none;
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
      line-height: 1.5;
    }

    .${filledInputClasses.input} {
      // border-radius below gives a better look on native browser highlight
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
      height: auto;
      padding-right: 0;
      padding-left: 0;

      &::placeholder {
        color: ${({ theme }) => theme.palette.textbox.placeholder};
        opacity: 1;
      }
    }
  }
`;

export const Container = styled("div", {
  label: "input-container",
})`
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.textbox.border};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  display: flex;
  padding: ${({ theme }) => theme.spacing(0, 1.5)};

  .faye__input--error & {
    border-color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const StartAdornment = styled("div", {
  label: "start-adornment",
})`
  margin-right: ${({ theme }) => theme.spacing(1)};
  position: relative;
`;

export const EndAdornment = styled("div", {
  label: "end-adornment",
})`
  margin-left: ${({ theme }) => theme.spacing(1)};
  position: relative;
`;

export const Wrapper = styled("div", {
  label: "input-wrapper",
})`
  width: 100%;
`;
