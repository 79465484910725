import { styled } from "@mui/material/styles";

import OnboardingLinkComponent from "components/common/onboarding-link";
import { OnboardingLinkProps } from "components/common/onboarding-link/types";

export const Container = styled("header", {
  label: "header",
})`
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.appBar};

  &::before {
    background: white;
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: ${({ theme }) => theme.transitions.create("opacity")};
  }

  &.collapsed::before {
    opacity: 1;
  }
`;

export const List = styled("ul", {
  label: "list",
})`
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled("li", {
  label: "list-item",
})`
  display: block;
  font-size: 14px;
  font-weight: 600;

  & + & {
    margin-left: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => theme.breakpoints.down("md")} {
      margin: ${({ theme }) => theme.spacing(0, 2, 0, 0)};
      order: -1;
    }
  }
`;

export const Nav = styled("nav", {
  label: "nav",
})`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  padding: ${({ theme }) => theme.spacing(4, 10, 2, 10)};
  transition: ${({ theme }) => theme.transitions.create("padding")};

  ${({ theme }) => theme.breakpoints.down("tablet")} {
    padding-right: ${({ theme }) => theme.spacing(4)};
    padding-left: ${({ theme }) => theme.spacing(4)};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: ${({ theme }) => theme.spacing(2)};
    transition: none;
  }

  &.collapsed {
    padding-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const OnboardingLink = styled((props: OnboardingLinkProps) => (
  <OnboardingLinkComponent {...props} />
), {
  label: "onboarding-link",
})`
  padding-right: ${({ theme }) => theme.spacing(6)};
  padding-left: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding-right: ${({ theme }) => theme.spacing(2.5)};
    padding-left: ${({ theme }) => theme.spacing(2.5)};
  }
`;
