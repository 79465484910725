import { useEffect, useRef, useState } from "react";

export default function useScrolled(offset: number) {
  const isRequesting = useRef(false);
  const [scrolled, setScrolled] = useState<boolean | null>(null);

  function scrolledIsActiveListener() {
    if (!isRequesting.current) {
      isRequesting.current = true;

      window.requestAnimationFrame(() => {
        if (window.scrollY < offset) {
          setScrolled(false);
        }

        isRequesting.current = false;
      });
    }
  }

  function scrolledIsInactiveListener() {
    if (!isRequesting.current) {
      isRequesting.current = true;

      window.requestAnimationFrame(() => {
        if (window.scrollY >= offset) {
          setScrolled(true);
        }

        isRequesting.current = false;
      });
    }
  }

  useEffect(() => {
    setScrolled(window.scrollY >= offset);
  }, []);

  useEffect(() => {
    const listener = scrolled
      ? scrolledIsActiveListener
      : scrolledIsInactiveListener;

    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    }
  }, [scrolled]);

  return scrolled;
}
