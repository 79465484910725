import { useEffect, useRef, useState } from "react";

import { mixpanelTrack } from "utils/tracking/mixpanel";

import Button from "./button";
import Input from "./input";
import { Container } from "./styles";
import { NewsletterProps, Status } from "./types";

const VERIFY_EMAIL_REGEXP = new RegExp(
  "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" +
  "(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
);

export default function Newsletter({
  buttonProps,
  className,
  inputProps,
  placement,
}: NewsletterProps) {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<Status>(null);
  const statusTimeout = useRef(0);

  useEffect(() => {
    if (status === "init") {
      const trimmedEmail = `${email}`.trim();

      window.clearTimeout(statusTimeout.current);

      if (!VERIFY_EMAIL_REGEXP.test(trimmedEmail)) {
        setStatus("error");
      } else {
        setStatus("loading");
        mixpanelTrack("Lead added", {
          email: trimmedEmail,
          location: placement,
        });
      }
    } else if (status === "loading") {
      statusTimeout.current = window.setTimeout(() => {
        setStatus("done");
      }, Math.floor(Math.random() * (3000 - 1500) + 1500)); // > 1500 && < 3000
    } else if (status === "done") {
      statusTimeout.current = window.setTimeout(() => {
        setStatus(null);
        setEmail("");
      }, 2000);
    } else if (status === "error") {
      statusTimeout.current = window.setTimeout(() => {
        setStatus(null);
      }, 4000);
    }
  }, [status]);

  return (
    <Container
      className={className}
      noValidate
      onSubmit={event => {
        event.preventDefault();

        if (status === null || status === "error") {
          setStatus("init");
        }
      }}
    >
      <Input
        {...inputProps}
        onChange={event => setEmail(event.target.value)}
        status={status}
        value={email}
      />

      <Button
        {...buttonProps}
        status={status}
      />
    </Container>
  );
};
