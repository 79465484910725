import { useContext, useEffect } from "react";

import PlainButton from "components/common/button/plain";

import { FooterContext } from "../..";
import { Container, Link, Title } from "./styles";
import { ListProps } from "./types";

export default function List({ items, title }: ListProps) {
  const { dialogs } = useContext(FooterContext);

  useEffect(() => {
    for (const { path } of items) {
      if (path === "advisors") {
        dialogs.showAdvisors.set(false);
      } else if (path === "newsletter") {
        dialogs.showNewsletter.set(false);
      }
    }
  }, []);

  return (
    <Container>
      <Title>
        {title}
      </Title>

      {items.map(({ label, path }) => {
        if (path === "advisors") {
          return (
            <PlainButton
              className="item"
              key={label}
              onClick={() => {
                dialogs.showAdvisors.set(true);
              }}
            >
              {label}
            </PlainButton>
          );
        } else if (path === "newsletter") {
          return (
            <PlainButton
              className="item"
              key={label}
              onClick={() => {
                dialogs.showNewsletter.set(true);
              }}
            >
              {label}
            </PlainButton>
          );
        } else {
          return (
            <Link
              className="item"
              key={label}
              to={path}
            >
              {label}
            </Link>
          );
        }
      })}
    </Container>
  );
};
