import { styled } from "@mui/material/styles";

import SocialLogosComponent from "../social-logos";
import { SocialLogosProps } from "../social-logos/types";

export const Container = styled("div", {
  label: "follow-us",
})`
  font-size: 15px;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 13px;
    font-weight: 700;
  }

  &.desktop {
    ${({ theme }) => theme.breakpoints.down("md")} {
      display: none;
    }
  }

  &.mobile {
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: none;
    }
  }
`;

export const SocialLogos = (props: Partial<SocialLogosProps>) => (
  <SocialLogosComponent variant="compact" {...props} />
)

export const Title = styled("div", {
  label: "title",
})`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
