import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

export const Component = styled(FormHelperText, {
  label: "helper-text",
})`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  margin: ${({ theme }) => theme.spacing(0.5, 1.5, 0)};
`;
