import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";

import OutlinedInput, {
  outlinedInputClasses,
  OutlinedInputProps,
} from "@mui/material/OutlinedInput";

import MailOutline from "assets/images/icons/mail-outline.svg";

const Adornment = styled(InputAdornment, {
  label: "adornment",
})`
  color: inherit;

  & > svg {
    fill: currentColor;
  }
`;

export const Container = styled((props: OutlinedInputProps) => (
  <OutlinedInput
    startAdornment={
      <Adornment position="start">
        <MailOutline />
      </Adornment>
    }
    {...props}
  />
), {
  label: "input",
})`
  background: ${({ theme }) => theme.palette.p_white.full};
  flex: 1 1 100%;

  .${outlinedInputClasses.input} {
    &::placeholder {
      color: ${({ theme }) => theme.palette.p_grey.border};
    }
  }

  .${outlinedInputClasses.notchedOutline} {
    border-color: ${({ theme }) => theme.palette.p_grey.border};
  }

  &.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline} {
    border-color: ${({ theme }) => theme.palette.error.main};
  }

  &.${outlinedInputClasses.disabled} {
    color: inherit;
  }

  .${outlinedInputClasses.disabled} {
    -webkit-text-fill-color: inherit;
  }
`;
