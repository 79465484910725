import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { PrismicProvider } from "@prismicio/react";
import { IntercomProvider } from "react-use-intercom";

import {
  Link,
  RouteUpdateArgs,
  ShouldUpdateScrollArgs,
  WrapPageElementBrowserArgs,
  WrapRootElementBrowserArgs
} from "gatsby";

import AppLayout from "components/common/layout/app";
import MainLayout from "components/common/layout/main";
import linkResolver from "utils/linkResolver";
import theme from "utils/theme";
import { mixpanelTrack } from "utils/tracking/mixpanel";

import "styles/fonts/twk-lausanne.css";
import "styles/fonts/utopia.css";
import "styles/global.css";

export function onRouteUpdate({
  location: { pathname },
  prevLocation,
}: RouteUpdateArgs) {
  // The first "Viewed ..." event is being sent from src/html.tsx snippet
  if (prevLocation !== null) {
    mixpanelTrack(`Viewed ${pathname}`);
  }
}

export function shouldUpdateScroll({
  prevRouterProps,
  routerProps,
}: ShouldUpdateScrollArgs) {
  if (
    !prevRouterProps && // If it's the first load...
    routerProps.location.pathname.startsWith("/faq/") && // ...on a FAQ page...
    routerProps.location.hash // ...and the URL contains a hash
  ) {
    return false;
  } else if ( // false if previous and current page are FAQ pages...
    routerProps.location.pathname !== "/faq/" && // ...but not the root page
    prevRouterProps?.location.pathname.startsWith("/faq/") &&
    routerProps.location.pathname.startsWith("/faq/")
  ) {
    return false;
  }

  return true;
}

export function wrapPageElement({
  element,
  props: {
    location,
  },
}: WrapPageElementBrowserArgs) {
  if (location.pathname.startsWith("/app/")) {
    return (
      <AppLayout>
        {element}
      </AppLayout>
    );
  }

  return (
    <MainLayout location={location}>
      {element}
    </MainLayout>
  );
}

export function wrapRootElement({
  element,
}: WrapRootElementBrowserArgs) {
  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link className="internal-link" to={href} {...props} />
      )}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {process.env.GATSBY_CHAT_SERVICE !== "intercom" && element}
        {process.env.GATSBY_CHAT_SERVICE === "intercom" && (
          <IntercomProvider appId={process.env.GATSBY_INTERCOM_APP_ID}>
            {element}
          </IntercomProvider>
        )}
      </ThemeProvider>
    </PrismicProvider>
  );
}
