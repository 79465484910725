import { styled } from "@mui/material/styles";

import LogoComponent from "assets/images/logo.svg";
import Link from "components/common/link";

export const Container = styled("div", {
  label: "top",
})`
  align-items: start;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

export const Logo = styled(LogoComponent, {
  label: "logo",
})`
  display: block;
  fill: currentColor;
  width: 90px;
  height: auto;
`;

export const LogoLink = styled(Link, {
  label: "logo-container",
})`
  color: inherit;
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-bottom: 0;
  }
`;
