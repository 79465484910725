import { withArtDirection } from "gatsby-plugin-image";

import GatsbyImage from "components/common/gatsby-image";
import RichText from "components/common/rich-text";

import { FULL_SCREEN_MEDIA_QUERY } from "../styles";
import { Container, Content } from "./styles";
import { InfoProps } from "./types";

export default function Info({
  background,
  backgroundFullScreen,
  description,
  title,
}: InfoProps) {
  const artDirectedImage = withArtDirection(
    background.gatsbyImageData,
    [{
      image: backgroundFullScreen.gatsbyImageData,
      media: FULL_SCREEN_MEDIA_QUERY.replace("@media ", ""),
    }]
  );

  return (
    <Container>
      <Content>
        <RichText field={title}/>
        <RichText field={description}/>
      </Content>

      <GatsbyImage
        alt={background.alt}
        gatsbyImageData={artDirectedImage}
        objectPosition="top center"
        loading="eager"
      />
    </Container>
  );
}
