import { forwardRef } from "react";

import { Link as LinkComponent, GatsbyLink } from "./styles";
import { LinkProps } from "./types";

const LOCAL_PATH_REGEXP = /^\/[^/]?|^\.{1,2}\//;

// Gatsby's Link has issues with forwardRef, so use "any" here until solved:
// https://github.com/gatsbyjs/gatsby/issues/34325
export default forwardRef<any, LinkProps>(function Link({
  to,
  ...props
}, ref) {
  if (LOCAL_PATH_REGEXP.test(to)) {
    return (
      <GatsbyLink
        ref={ref}
        to={to}
        {...props}
      />
    );
  }

  return (
    <LinkComponent
      href={to}
      ref={ref}
      {...props}
    />
  );
});
