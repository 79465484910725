import Button, { buttonClasses, ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

import CircularProgressComponent, {
  CircularProgressProps
} from "@mui/material/CircularProgress";

import CheckAsset from "assets/images/icons/check.svg";

export const Check = styled(CheckAsset, {
  label: "check",
})`
  fill: currentColor;
`;

export const CircularProgress = styled((props: CircularProgressProps) => (
  <CircularProgressComponent size={20} {...props} />
))`
  color: inherit;
`;

export const Container = styled(forwardRef<
  HTMLButtonElement | null,
  ButtonProps
>((props, ref) => (
  <Button ref={ref} variant="contained" {...props} />
)), {
  label: "button",
})`
  box-shadow: none;
  flex: 0 0 auto;
  font-size: 18px;
  margin-left: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1.5, 5)};
  text-transform: none;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 16px;
    padding: ${({ theme }) => theme.spacing(1.5, 3)};
  }

  &,
  &.${buttonClasses.disabled} {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.p_white.full};
  }
`;
