import { Container, SocialLogos, Title } from "./styles";
import { FollowUsProps } from "./types";

const TITLE = "Follow us";

export default function FollowUs({
  onlyShowOn
}: FollowUsProps) {
  return (
    <Container className={onlyShowOn}>
      <Title>{TITLE}</Title>
      <SocialLogos />
    </Container>
  );
};
