import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { isMobile } from "react-device-detect";

import RichText from "components/common/rich-text";
import { cleanPhoneNumber } from "utils/phone-number";
import { upperCaseFirst } from "utils/strings";
import { mixpanelTrack } from "utils/tracking/mixpanel";
import { DEFAULT_VALIDATION_RESULT } from "utils/validation";
import emailValidation from "utils/validation/email";

import { FULL_SCREEN_MEDIA_QUERY } from "../../styles";
import { Button, Container, Input, MailIcon, PhoneIcon, Row } from "./styles";
import { FormProps } from "./types";

export default function Form({ labels, onSubmit, title }: FormProps) {
  const isFullScreen = useMediaQuery(FULL_SCREEN_MEDIA_QUERY);

  const [firstName, setFirstName] = useState({
    validation: DEFAULT_VALIDATION_RESULT,
    value: "",
  });

  const [lastName, setLastName] = useState({
    validation: DEFAULT_VALIDATION_RESULT,
    value: "",
  });

  const [companyName, setCompanyName] = useState({
    value: "",
  });

  const [email, setEmail] = useState({
    validation: DEFAULT_VALIDATION_RESULT,
    value: "",
  });

  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
  });

  return (
    <Container
      noValidate
      onSubmit={(event) => {
        event.preventDefault();

        if (
          !firstName.validation.valid ||
          !lastName.validation.valid ||
          !email.validation.valid
        ) {
          return;
        }

        mixpanelTrack("prospect_advisor", {
          "Agency": companyName.value || null,
          "Email": email.value || null,
          "First name": firstName.value,
          "Last name": lastName.value,
          "Phone #": phoneNumber.value || null,
        });

        onSubmit({ firstName: firstName.value });
      }}
    >
      <RichText field={title}/>

      <Row>
        <Input
          autoComplete="given-name"
          autoFocus={!isMobile}
          label={labels.firstName}
          onChange={(event) => {
            setFirstName({
              ...firstName,
              value: upperCaseFirst(event.target.value),
            });
          }}
          onValidation={(validation) => {
            setFirstName({
              ...firstName,
              validation,
            });
          }}
          required
          validate={(value) => (value as string).length >= 2}
          value={firstName.value}
        />

        <Input
          autoComplete="family-name"
          label={labels.lastName}
          onChange={(event) => {
            setLastName({
              ...lastName,
              value: upperCaseFirst(event.target.value),
            });
          }}
          onValidation={(validation) => {
            setLastName({
              ...lastName,
              validation,
            });
          }}
          required
          validate={(value) => (value as string).length >= 2}
          value={lastName.value}
        />
      </Row>

      <Row>
        <Input
          autoComplete="organization"
          label={labels.companyName}
          onChange={(event) => {
            setCompanyName({
              value: event.target.value,
            });
          }}
          value={companyName.value}
        />
      </Row>

      <Row>
        <Input
          autoComplete="email"
          InputProps={{
            startAdornment: <MailIcon/>
          }}
          label={labels.email}
          onChange={(event) => {
            setEmail({
              ...email,
              value: event.target.value,
            });
          }}
          onValidation={(validation) => {
            setEmail({
              ...email,
              validation,
            });
          }}
          type="email"
          validate={(value) => value
            ? emailValidation(value as string)
            : true
          }
        />
      </Row>

      <Row>
        <Input
          autoComplete="tel"
          InputProps={{
            startAdornment: <PhoneIcon/>
          }}
          label={labels.phoneNumber}
          onChange={(event) => {
            setPhoneNumber({
              value: cleanPhoneNumber(event.target.value),
            });
          }}
          type="tel"
          value={phoneNumber.value}
        />
      </Row>

      <Row>
        <Button type="submit" variant={isFullScreen ? "outlined" : "standard"}>
          {labels.submitButton}
        </Button>
      </Row>
    </Container>
  );
}
