import { styled } from "@mui/material/styles";

import LinkComponent from "components/common/link";

export const Container = styled("div", {
  label: "bottom",
})`
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 1;
  margin-top: ${({ theme }) => theme.spacing(10)};
  opacity: 0.8;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 10px;
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: block;
    margin-bottom: 0;
  }
`;

export const Copyright = styled("div", {
  label: "copyright",
})`
  font-size: 13px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: inherit;
    line-height: 1.5;
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export const Disclaimer = styled("div", {
  label: "disclaimer",
})`
  line-height: 1.5;

  a {
    color: inherit;
  }
`;

export const Link = styled(LinkComponent, {
  label: "link",
})`
  color: inherit;
`;
