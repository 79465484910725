import { Script } from "gatsby";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef } from "react";

declare global {
  interface Window {
    FrontChat: (method: string, options?: { [key: string]: unknown }) => void;
  }
}

function initFrontChat() {
  if (window.FrontChat) {
    window.FrontChat("init", {
      chatId: process.env.GATSBY_FRONTCHAT_ID,
      useDefaultLauncher: true,
    });
  }
}

function shutdownFrontChat() {
  if (window.FrontChat) {
    window.FrontChat("shutdown");
  }
}

export default function FrontChat() {
  const pendingInit = useRef<boolean | null>(null);
  const isDesktopViewport = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("tablet");
  });

  useEffect(() => {
    if (pendingInit.current !== false) {
      pendingInit.current = (!pendingInit.current && isDesktopViewport) || null;
    } else if (isDesktopViewport) {
      initFrontChat();
    } else {
      shutdownFrontChat();
    }
  }, [isDesktopViewport]);

  useEffect(() => {
    return () => {
      shutdownFrontChat();
    };
  }, []);

  return (
    <Script
      onLoad={() => {
        if (pendingInit.current || isDesktopViewport) {
          initFrontChat();
        }
        pendingInit.current = false;
      }}
      strategy="idle"
      src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
    />
  );
};
