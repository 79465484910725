import { styled } from "@mui/material/styles";

import { FULL_SCREEN_MEDIA_QUERY } from "../styles";

export const Container = styled("div", {
  label: "info",
})`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  position: relative;

  ${FULL_SCREEN_MEDIA_QUERY} {
    flex: 0;
    position: static;
  }

  & > .gatsby-image-wrapper {
    margin-top: auto;

    ${FULL_SCREEN_MEDIA_QUERY} {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`;

export const Content = styled("div", {
  label: "content",
})`
  padding: ${({ theme }) => theme.spacing(5, 4.5, 0, 5)};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  ${FULL_SCREEN_MEDIA_QUERY} {
    padding: ${({ theme }) => theme.spacing(10, 2, 0)};
    position: static;
  }

  & > h2 {
    font-family: ${({ theme }) => theme.typography.main.fontFamily};
    font-size: 36px;
    font-weight: 400;
    line-height: 1.28;

    ${FULL_SCREEN_MEDIA_QUERY} {
      text-align: center;
    }
  }

  & > p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;
    margin-top: ${({ theme }) => theme.spacing(4.5)};

    ${FULL_SCREEN_MEDIA_QUERY} {
      margin-top: ${({ theme }) => theme.spacing(1)};
      text-align: center;
    }
  }
`;
