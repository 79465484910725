const VALID_EMAIL = new RegExp(
  "^(" +
  "([^<>()[\\]\\\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\\"]+)*)" +
  "|" +
  "(\\\".+\\\")" +
  ")" +
  "@(" +
  "(\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])" +
  "|" +
  "(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})" +
  ")$"
);

export default function emailValidation(value: string) {
  const message: string = "Please enter a valid email"; // TODO Move to Prismic
  return VALID_EMAIL.test(value.trim()) || message;
}
