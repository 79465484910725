import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const primary = "#4726D1";

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 720,
      tablet: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: "#F5F5F5",
    },
    components: {
      advisorsDialog: {
        background: "#F7EDFF",
      },
      backdrop: "#444444",
      pagination: {
        disabled: {
          text: "#c8c8c8",
        },
        text: "#999999",
      },
    },
    error: {
      main: red.A400,
    },
    p_black: {
      text: "#27303B",
    },
    p_blue: {
      lightest: "#F7FCFF",
    },
    p_green: {
      addon: "#E2F6D7",
    },
    p_grey: {
      background: "#B1ACBF",
      backgroundSecondary: "#E9E9E9",
      border: "#BFBFBF",
      borderLight: "#EBEBEB",
      light: "#D8D8D8",
      lighter: "#FAFAFA",
      text: "#878787",
      textDark: "#787878",
      textDarker: "#4F4F4F",
    },
    p_purple: {
      border: "#A393E8",
      borderLight: "#DDDBE3",
      light: "#F6EDFF",
      lighter: "#F8F7FA",
      lightest: "#FAF3FF",
      medium: "#ECD8FF",
      mediumLight: "#F2E4FF",
    },
    p_white: {
      full: "#FFFFFF",
    },
    p_yellow: {
      addon: "#FBE8A9",
      light: "#E2D4BC",
    },
    primary: {
      contrastText: "#FFFFFF",
      light: "#F6EDFF",
      main: primary,
    },
    secondary: {
      light: "#CAF0F4",
      main: "#E2FBFF",
    },
    tag:{
      default: {
        background: "#F4F4F4",
        text: "#878787",
      },
      department: {
        background: "#BEE8C2",
        text: "#416720",
      },
    },
    text: {
      primary,
      secondary: "#444444",
    },
    textbox: {
      border: "#c8c8c8",
      label: "#999999",
      placeholder: "#999999",
      text: "#444444",
    },
  },
  typography: {
    fontFamily: [
      "TWK Lausanne",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    main: {
      fontFamily: [
        "utopia-std-display",
        "serif",
      ].join(","),
    },
  },
});
