import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

export const Component = styled(ButtonBase, {
  label: "base-button",
})`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 1;
`;
