import { type FilledTextFieldProps } from "@mui/material/TextField";
import classNames from "classnames";
import { forwardRef, useEffect, useRef, useState } from "react";

import HelperText from "components/common/helper-text";

import validation, {
  type ValidationFunction,
  type ValidationResult,
  DEFAULT_VALIDATION_RESULT,
} from "utils/validation";

import {
  Component,
  Container,
  EndAdornment,
  StartAdornment,
  Wrapper,
} from "./styles";

export interface InputData {
  valid: boolean | null;
  value: string;
}

export interface InputProps extends Omit<
  FilledTextFieldProps,
  "size" | "variant"
> {
  onValidation?: (validation: ValidationResult) => void;
  validate?: ValidationFunction | ValidationFunction[];
}

export default forwardRef<
  HTMLDivElement,
  InputProps
>(function Input({
  className,
  FormHelperTextProps,
  helperText,
  InputProps: {
    endAdornment,
    startAdornment,
    ...InputProps
  } = {},
  onBlur,
  onChange,
  onValidation,
  validate,
  ...props
}, ref) {
  const onValidationRef = useRef(onValidation);
  const validateRef = useRef(validate);
  const [blurredWithError, setBlurredWithError] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [validationResult, setValidationResult] = useState(
    DEFAULT_VALIDATION_RESULT
  );

  const valueInProps = "value" in props;
  const value = valueInProps ? (props.value || "") : inputValue;
  const error = blurredWithError && validationResult.valid === false;

  const helperTextValue = helperText ?? (
    blurredWithError
      ? validationResult.helperText
      : null
  );

  onValidationRef.current = onValidation;
  validateRef.current = validate;

  useEffect(() => {
    if (validateRef.current) {
      setValidationResult(validation(validateRef.current, value));
    }
  }, [value]);

  useEffect(() => {
    if (onValidationRef.current) {
      onValidationRef.current(validationResult);
    }
  }, [validationResult]);

  return (
    <Wrapper
      className={classNames(className, "faye__input", {
        "faye__input--error": props.error ?? error,
      })}
    >
      <Container className="faye__input__container">
        {startAdornment && (
          <StartAdornment>
            {startAdornment}
          </StartAdornment>
        )}

        <Component
          error={error}
          InputProps={InputProps}
          onBlur={(...args) => {
            if (validateRef.current) {
              const validationResult = validation(
                validateRef.current,
                value
              );

              setBlurredWithError(
                validationResult.valid === false
              );
            }

            if (onBlur) {
              onBlur(...args);
            }
          }}
          onChange={(event) => {
            if (!valueInProps) {
              setInputValue(event.target.value);
            }

            if (onChange) {
              onChange(event);
            }
          }}
          ref={ref}
          {...props}
        />

        {endAdornment && (
          <EndAdornment>
            {endAdornment}
          </EndAdornment>
        )}
      </Container>

      {helperTextValue && (
        <HelperText
          error={props.error ?? error}
          {...FormHelperTextProps}
        >
          {helperTextValue}
        </HelperText>
      )}
    </Wrapper>
  );
});
