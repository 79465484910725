import RichText from "components/common/rich-text";

import Columns from "../columns";
import { Container, Content, Copyright, Disclaimer, Link } from "./styles";
import { BottomProps } from "./types";

const COPYRIGHT_MESSAGE = `© %y Zenner, Inc. All rights reserved.`;

export default function Bottom({ disclaimer, year }: BottomProps) {
  return (
    <Container>
      <Content>
        <Columns>
          <div>
            <Link to="/terms-of-use/">Terms of use</Link>
          </div>
          <div>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </div>
        </Columns>

        <Copyright>
          {COPYRIGHT_MESSAGE.replace("%y", year)}
        </Copyright>
      </Content>

      <Disclaimer>
        <RichText field={disclaimer} migration="p" />
      </Disclaimer>
    </Container>
  );
};
