import { buttonBaseClasses } from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

import BaseButton from "./base";

export const Component = styled(BaseButton, {
  label: "button",
})`
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(2.5, 5)};

  &.${buttonBaseClasses.disabled} {
    background: ${({ theme }) => theme.palette.p_grey.light};
    color: ${({ theme }) => theme.palette.p_white.full};
    // MUI disables pointer-events, it may show the wrong
    // cursor sometimes, so reenable it
    pointer-events: auto;
  }

  &.outlined {
    background: ${({ theme }) => theme.palette.p_white.full};
    // Use box-shadow to create an inner border
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.primary.main} inset;
    color: ${({ theme }) => theme.palette.primary.main};

    &.${buttonBaseClasses.disabled} {
      box-shadow:
        0
        0
        0
        1px
        ${({ theme }) => theme.palette.p_grey.light}
        inset;
      color: ${({ theme }) => theme.palette.p_grey.light};
    }
  }
`;
