import { useContext } from "react";

import Columns from "../columns";
import { MainLayoutContext } from "../..";
import AppLinks from "./app-links";
import FollowUs from "./follow-us";
import List from "./list";
import { Container, Logo, LogoLink } from "./styles";
import { TopProps } from "./types";

export default function Top({ lists }: TopProps) {
  const { homePath } = useContext(MainLayoutContext);

  return (
    <Container>
      <div>
        <LogoLink to={homePath.value}>
          <Logo />
        </LogoLink>

        <FollowUs
          onlyShowOn="desktop"
        />
      </div>

      <AppLinks />

      <Columns>
        {lists.map(({ items, title }) => (
          <List
            items={items}
            key={title}
            title={title}
          />
        ))}

        <FollowUs onlyShowOn="mobile" />
      </Columns>
    </Container>
  );
};
