import { styled } from "@mui/material/styles";

import { FULL_SCREEN_MEDIA_QUERY } from "../styles";

export const Container = styled("div", {
  label: "cta",
})`
  color: ${({ theme }) => theme.palette.text.secondary};
  flex: 1 1 50%;
  padding: ${({ theme }) => theme.spacing(0, 4.5, 10)};

  ${FULL_SCREEN_MEDIA_QUERY} {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(5, 2)};
  }
`;
