import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

import DrawerCloseAsset from "assets/images/icons/drawer-close.svg";
import DrawerOpenAsset from "assets/images/icons/drawer-open.svg";
import LinkComponent from "components/common/link";

export const Container = styled("div", {
  label: "drawer",
})`
  display: flex;
  padding: 0;
  position: relative;

  ${({ theme }) => theme.breakpoints.down("md")} {
    background: white;
    flex-direction: column-reverse;
    padding-top: ${({ theme }) => theme.spacing(12)};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, -100%, 0);
    transition: ${({ theme }) => theme.transitions.create("transform")};
    z-index: -1;
  }

  &.active {
    ${({ theme }) => theme.breakpoints.down("md")} {
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const DrawerClose = styled(DrawerCloseAsset, {
  label: "drawer-open",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(2)};
  height: auto;
`;

export const DrawerOpen = styled(DrawerOpenAsset, {
  label: "drawer-open",
})`
  fill: currentColor;
  width: ${({ theme }) => theme.spacing(2)};
  height: auto;
`;

export const Link = styled(LinkComponent, {
  label: "link",
})`
  & + & {
    margin-left: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => theme.breakpoints.down("md")} {
      margin-left: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    border-top: 1px solid ${({ theme }) => theme.palette.p_blue.lightest};
    font-size: 16px;
    padding: ${({ theme }) => theme.spacing(2)};
    position: relative;
    text-align: center;
  }
`;

export const Trigger = styled(ButtonBase, {
  label: "trigger",
})`
  display: none;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: flex;
  }
`;
