import { styled } from "@mui/material/styles";

import LinkComponent from "components/common/link";

export const Container = styled("div", {
  label: "list",
})`
  align-items: start;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 1;

  & > .item {
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }

  & > .item + .item {
    ${({ theme }) => theme.breakpoints.down("md")} {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

export const Link = styled(LinkComponent, {
  label: "link",
})`
  color: inherit;
  letter-spacing: 0.01em;

  @supports (filter: invert(1)) {
    // During callout action, Safari iOS adds a gray background to links with a
    // light color. To fix this, we use the color opposite to the secondary main
    // color — the color inherited by this component — and invert it using a CSS
    // filter.

    color: #1D0400;
    filter: invert(1);
  }
`;

export const Title = styled("div", {
  label: "title",
})`
  font-weight: 700;
`;
