import { useEffect, useRef, useState } from "react";

import { Check, CircularProgress, Container } from "./styles";
import { ButtonProps } from "./types";

export default function Button({
  className,
  status,
}: ButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [width, setWidth] = useState("auto");

  useEffect(() => {
    if (status === "init") {
      setWidth(`${ref.current!.offsetWidth}px`);
    } else if (status === null || status === "error") {
      setWidth("auto");
    }
  }, [status]);

  return (
    <Container
      className={className}
      disabled={status !== null && status !== "error"}
      ref={ref}
      style={{ width }}
      type="submit"
    >
      {
        status === "loading"
          ? <CircularProgress />
          : status === "done"
            ? <Check />
            : "Sign up"
      }
    </Container>
  );
};
