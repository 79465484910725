import { styled } from "@mui/material/styles";

import { BaseButtonProps } from "../base/types";
import BaseButton from "../base";

export const Component = styled((props: BaseButtonProps) => (
  <BaseButton disableRipple {...props} />
), {
  label: "plain-button",
})`
  line-height: inherit;
`;
