import { styled } from "@mui/material/styles";

import PlusIconAsset from "assets/images/icons/plus.svg";
import LogoAsset from "assets/images/logo.svg";
import ImageComponent from "components/common/image";

export const Container = styled("div", {
  label: "logo",
})`
  align-items: center;
  display: flex;
  position: relative;
`;

export const Image = styled(LogoAsset, {
  label: "image",
})`
  display: block;
  fill: currentColor;
  position: relative;
  width: auto;
  height: ${({ theme }) => theme.spacing(5.5)};
  z-index: 1;
`;

export const LogoContainer = styled("div", {
  label: "logo-container",
})`
  position: relative;
`;

export const PartnerLogo = styled(ImageComponent, {
  label: "partner-logo",
})`
  width: auto;
  height: ${({ theme }) => theme.spacing(5.5)};

  ${({ theme }) => theme.breakpoints.down("tablet")} {
    display: none;
  }
`;

export const PlusIcon = styled(PlusIconAsset, {
  label: "plus-icon",
})`
  fill: ${({ theme }) => theme.palette.text.secondary};
  margin: ${({ theme }) => theme.spacing(0, 2.5)};
  width: auto;
  height: ${({ theme }) => theme.spacing(1.75)};

  ${({ theme }) => theme.breakpoints.down("tablet")} {
    display: none;
  }
`;

export const Version = styled("div", {
  label: "version",
})`
  cursor: default;
  display: block;
  font-size: 13px;
  opacity: 0;
  position: absolute;
  top: 100%;
  transform: translate3d(0, ${({ theme }) => theme.spacing(-2)}, 0);
  transition:
    ${({ theme }) => theme.transitions.create("opacity")},
    ${({ theme }) => theme.transitions.create("transform")};
  user-select: none;
  width: ${({ theme }) => theme.spacing(6)};
  height: ${({ theme }) => theme.spacing(6)};

  &.show {
    opacity: 1;
    transform: translate3d(0, ${({ theme }) => theme.spacing(-1.75)}, 0);
  }
`;
