import { OutboundLink } from "gatsby-plugin-google-gtag";
import { forwardRef } from "react";

import { mixpanelTrack } from "utils/tracking/mixpanel";

import { Container } from "./styles";
import { OnboardingLinkProps } from "./types";

export default forwardRef<
  HTMLButtonElement,
  OnboardingLinkProps
>(function OnboardingLink({
  label,
  placement,
  ...props
}, ref) {
  return (
    <Container
      {...props}
      {...{ href: process.env.GATSBY_ONBOARDING_URL }} // Avoid type error
      LinkComponent={OutboundLink}
      onClick={() => {
        if (placement !== undefined) {
          mixpanelTrack("Clicked CTA", {
            cta_location: placement,
          });
        }
      }}
      ref={ref}
    >
      {label || "Check pricing"}
    </Container>
  );
});
