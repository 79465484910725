import BackdropComponent from "@mui/material/Backdrop";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import { alpha, styled } from "@mui/material/styles";

import CloseIconAsset from "assets/images/icons/close.svg";
import PlainButton from "components/common/button/plain";
import theme from "utils/theme";

const MIN_DIALOG_HEIGHT = theme.spacing(72.5);

export const FULL_SCREEN_MEDIA_QUERY =
  `${theme.breakpoints.down("md")}, (max-height: ${MIN_DIALOG_HEIGHT})`;

export const Backdrop = styled(BackdropComponent, {
  label: "backdrop",
})`
  background: ${({ theme }) => alpha(theme.palette.components.backdrop, 0.8)};
`;

export const CloseButton = styled(PlainButton, {
  label: "close-button",
})`
  position: absolute;
  top: ${({ theme }) => theme.spacing(8)};
  right: ${({ theme }) => theme.spacing(6)};
  z-index: 2;

  ${FULL_SCREEN_MEDIA_QUERY} {
    position: fixed;
    top: ${({ theme }) => theme.spacing(3)};
    right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const CloseIcon = styled(CloseIconAsset, {
  label: "close-icon",
})`
  fill: ${({ theme }) => theme.palette.primary.main};
  width: ${({ theme }) => theme.spacing(1)};
  height: auto;

  ${FULL_SCREEN_MEDIA_QUERY} {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

export const Container = styled(Dialog, {
  label: "advisors-dialog",
})`
  ${FULL_SCREEN_MEDIA_QUERY} {
    overflow-y: scroll;
  }

  .${dialogClasses.container} {
    ${FULL_SCREEN_MEDIA_QUERY} {
      align-items: flex-start;
    }
  }
`;

export const Paper = styled("div", {
  label: "paper",
})`
  background: ${({ theme }) => (
    theme.palette.components.advisorsDialog.background
  )};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow:
    ${({ theme }) => theme.spacing(0, 0.5, 2.5)}
    rgba(0, 0, 0, 0.12);
  display: flex;
  margin: ${({ theme }) => theme.spacing(4)};
  max-width: ${({ theme }) => theme.spacing(122.5)};
  min-height: ${MIN_DIALOG_HEIGHT};
  overflow: hidden;
  position: relative;
  width: 100%;

  ${FULL_SCREEN_MEDIA_QUERY} {
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 0;
    box-shadow: none;
    flex-direction: column;
    margin: 0;
    min-height: 100%;
  }
`;
