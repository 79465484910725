import { Container } from "./styles";
import { ColumnsProps } from "./types";

export default function Columns({ children }: ColumnsProps) {
  return (
    <Container>
      {children}
    </Container>
  );
};
