import { graphql, useStaticQuery } from "gatsby";

import Cta from "./cta";
import Info from "./info";
import { Backdrop, CloseButton, CloseIcon, Container, Paper } from "./styles";
import { AdvisorsDialogProps, QueryProps } from "./types";

export default function AdvisorsDialog({
  onClose,
  open,
  ...props
}: AdvisorsDialogProps) {
  const {
    prismicAdvisorsDialog: {
      data,
    },
  } = useStaticQuery<QueryProps>(graphql`
    query {
      prismicAdvisorsDialog {
        data {
          confirmation_cta_button_label
          confirmation_description {
            richText
          }
          confirmation_title {
            richText
          }
          form_company_name_label
          form_email_label
          form_first_name_label
          form_last_name_label
          form_phone_number_label
          form_submit_button_label
          form_title {
            richText
          }
          info_background {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              sizes: "(min-width: 1024px) 490px, (min-width: 800px) 47vw, (min-width: 720px) 46vw, 100vw"
            )
          }
          info_background_full_screen {
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
            )
          }
          info_description {
            richText
          }
          info_title {
            richText
          }
        }
      }
    }
  `);

  return (
    <Container
      {...props}
      onClose={(_, reason) => {
        if (reason === "escapeKeyDown") {
          onClose();
        }
      }}
      open={open}
      PaperComponent={({
        className: _className,
        elevation: _elevation,
        ...props
      }) => (
        <Paper {...props}/>
      )}
      slots={{
        backdrop: Backdrop,
      }}
      transitionDuration={0}
    >
      <CloseButton
        onClick={onClose}
      >
        <CloseIcon/>
      </CloseButton>

      <Info
        background={data.info_background}
        backgroundFullScreen={data.info_background_full_screen}
        description={data.info_description}
        title={data.info_title}
      />

      <Cta
        confirmation={{
          ctaButtonLabel: data.confirmation_cta_button_label,
          description: data.confirmation_description,
          title: data.confirmation_title,
        }}
        form={{
          labels: {
            companyName: data.form_company_name_label,
            email: data.form_email_label,
            firstName: data.form_first_name_label,
            lastName: data.form_last_name_label,
            phoneNumber: data.form_phone_number_label,
            submitButton: data.form_submit_button_label,
          },
          title: data.form_title,
        }}
        onClose={onClose}
      />
    </Container>
  );
}
