import { GatsbyImage as GatsbyImageComponent } from "gatsby-plugin-image";

import { GatsbyImageProps } from "./types";

export default function GatsbyImage({
  alt,
  gatsbyImageData,
  ...props
}: GatsbyImageProps) {
  return (
    <GatsbyImageComponent
      {...props}
      alt={alt || ""}
      image={gatsbyImageData}
    />
  );
};
